import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AddTaskMemberRequest,
  RemoveTaskMemberRequest,
  TaskMemberDto,
} from '@models/task-member';
import { TaskMemberApiService } from '@services/api';

@Injectable({
  providedIn: 'root',
})
export class TaskMemberService {
  constructor(private taskMemberApiService: TaskMemberApiService) {}

  getTaskMembers(id: string): Observable<TaskMemberDto[]> {
    return this.taskMemberApiService.getTaskMembers(id);
  }

  addTaskMember(
    id: string,
    body: AddTaskMemberRequest,
  ): Observable<TaskMemberDto> {
    return this.taskMemberApiService.addTaskMember(id, body);
  }

  removeTaskMember(id: string, body: RemoveTaskMemberRequest): Observable<any> {
    return this.taskMemberApiService.removeTaskMember(id, body);
  }
}
