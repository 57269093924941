import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FileService {
  private downloadedUrls = new Map();

  checkDownloaded(url: string): boolean {
    return this.downloadedUrls.has(url);
  }

  setDownloaded(url: string, objectUrl: string) {
    this.downloadedUrls.set(url, objectUrl);
  }

  getDownloaded(url: string): string {
    return this.downloadedUrls.get(url);
  }
}
