import { Injectable } from '@angular/core';
import { Event } from '../types';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedIn = false;
  public events: Event[] = [];
  constructor(private readonly keycloak: KeycloakService) {}

  async login() {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn) {
      const token = await this.keycloak.getToken();
      localStorage.setItem('access_token', token);
    } else {
      this.keycloak.login();
    }
  }

  logout(): void {
    localStorage.removeItem('access_token');
    this.keycloak.logout();
  }
}
