import { SelectOption } from '@models/form';

export enum RecurrenceTypeEnum {
  SINGLE = 'SINGLE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY_BY_WEEK_DAY = 'MONTHLY_BY_WEEK_DAY',
  MONTHLY_BY_MONTH_DAY = 'MONTHLY_BY_MONTH_DAY',
}

export const RecurrenceTypeNames: SelectOption<RecurrenceTypeEnum>[] = [
  {
    value: RecurrenceTypeEnum.SINGLE,
    name: 'Одиночное',
  },
  {
    value: RecurrenceTypeEnum.DAILY,
    name: 'Ежедневно',
  },
  {
    value: RecurrenceTypeEnum.WEEKLY,
    name: 'Еженедельно',
  },
  {
    value: RecurrenceTypeEnum.MONTHLY_BY_WEEK_DAY,
    name: 'Каждый месяц в опр. день недели',
  },
  {
    value: RecurrenceTypeEnum.MONTHLY_BY_MONTH_DAY,
    name: 'Каждый месяц в опр. день месяца',
  },
];

export const WeekDayNames: SelectOption<number>[] = [
  {
    value: 1,
    name: 'Пн',
  },
  {
    value: 2,
    name: 'Вт',
  },
  {
    value: 3,
    name: 'Ср',
  },
  {
    value: 4,
    name: 'Чт',
  },
  {
    value: 5,
    name: 'Пт',
  },
  {
    value: 6,
    name: 'Сб',
  },
  {
    value: 7,
    name: 'Вс',
  },
];

export const monthDaysArray = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export type RecurrenceInfoType =
  | BaseRecurInfoRequest
  | MonthlyByWeekDayRecurInfoRequest
  | WeeklyRecurInfoRequest;

export interface BaseRecurInfoRequest {
  type: string;
  each?: number;
  recurEnd?: string;
}

export type MonthlyByWeekDayRecurInfoRequest = BaseRecurInfoRequest & {
  lastWeek?: boolean;
};

export type WeeklyRecurInfoRequest = BaseRecurInfoRequest & {
  daysOfWeek?: number[];
};
