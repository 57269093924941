import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ProjectDto, ProjectResult } from '@models/project';
import { ImportJiraProjectRequest } from '@models/import-jira';

@Injectable({ providedIn: 'root' })
export class ImportJiraApiService {
  baseUrl = `${environment.baseUrl}/api/project/importer/jira`;

  constructor(private http: HttpClient) {}

  importJiraProject(body: ImportJiraProjectRequest): Observable<ProjectDto> {
    return this.http
      .post<ProjectResult>(this.baseUrl, body)
      .pipe(map((res) => res?.project));
  }
}
