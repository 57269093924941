import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { AllowedDto, ControlData } from '@models/form';

export type ControlsData = Record<string, any>;

export interface NestedField {
  dataField: string;
  nestedField: string;
  controlName: string;
}

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(private fb: FormBuilder) {}

  initialFormValue!: string;
  form!: FormGroup;

  createForm(controlsData: ControlData[], validators?: ValidatorFn[]): void {
    this.form = this.fb.group(this.initControls(controlsData), { validators });
  }

  private initControls(controlsData: ControlData[]): ControlsData {
    return controlsData.reduce(
      (controls, { name, initialValue = '', validators = [] }) => {
        controls[name] = [initialValue, ...validators];
        return controls;
      },
      {} as ControlsData,
    );
  }

  setData(data: AllowedDto, nestedFields?: NestedField[]): void {
    if (this.form.value) {
      this.form.reset();
    }

    Object.keys(data).forEach((key) => {
      if (key in this.form.controls) {
        const control = this.form.controls[key];
        const value = (data as Record<string, any>)[key];
        if (control) {
          control.setValue(value);
        }
      }
    });

    if (nestedFields) {
      nestedFields.forEach(({ dataField, nestedField, controlName }) => {
        const nestedData = (data as Record<string, any>)[dataField];
        if (
          nestedData &&
          typeof nestedData === 'object' &&
          nestedField in nestedData
        ) {
          const control = this.form.controls[controlName];
          if (control) {
            control.setValue((nestedData as Record<string, any>)[nestedField]);
          }
        }
      });
    }
  }

  private setInitialFormValue(): void {
    this.initialFormValue = JSON.stringify(this.form.value);
  }
}
