import { Injectable } from '@angular/core';
import { CreateHolidayEventRequest } from '@models/createHolidayEventRequest';
import { first, map, Observable } from 'rxjs';
import { CreateHolidayEventResult } from '@models/createHolidayEventResult';
import { HolidayEventDto, GetHolidaysRequest } from '@models/holiday-event';
import { DeleteHolidayEventByIdResult } from '@models/deleteHolidayEventByIdResult';
import { PatchHolidayEventRequest } from '@models/patchHolidayEventRequest';
import { PatchHolidayEventResult } from '@models/patchHolidayEventResult';
import { HolidayEventApiService } from './api';
import { GetHolidayEventsResult } from '@models/getHolidayEventsResult';

@Injectable({ providedIn: 'root' })
export class HolidayEventService {
  constructor(private holidayEventApiService: HolidayEventApiService) {}

  getHolidays(params?: GetHolidaysRequest): Observable<GetHolidayEventsResult> {
    return this.holidayEventApiService.getHolidays(params);
  }

  createHolidayEvent(
    body: CreateHolidayEventRequest,
  ): Observable<CreateHolidayEventResult> {
    return this.holidayEventApiService.addHoliday(body);
  }

  updateHolidayEvent(
    body: PatchHolidayEventRequest,
    id: number,
  ): Observable<PatchHolidayEventResult> {
    return this.holidayEventApiService.updateHoliday(body, id);
  }

  getHolidayById(id: number): Observable<HolidayEventDto | undefined> {
    return this.holidayEventApiService.getHolidayById(id).pipe(
      map((resp) => resp?.event),
      first(),
    );
  }

  deleteHoliday(id: number): Observable<DeleteHolidayEventByIdResult> {
    return this.holidayEventApiService.deleteHoliday(id);
  }
}
