import { Injectable } from '@angular/core';
import { TaskApiService } from '@services/api/task.api.service';
import { BehaviorSubject, first, Observable, tap } from 'rxjs';
import {
  CreateProjectTaskRequest,
  ProjectTaskDto,
  UpdateProjectTaskRequest,
} from '@models/task';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private taskApiService: TaskApiService) {}

  private readonly _tasks$: BehaviorSubject<ProjectTaskDto[]> =
    new BehaviorSubject<ProjectTaskDto[]>([]);
  loading = false;

  needToUpdate(update: boolean): boolean {
    return !this._tasks$.value.length || update;
  }

  getTasks(
    update = false,
    projectIds?: number[],
  ): Observable<ProjectTaskDto[]> {
    if (this.needToUpdate(update)) {
      this.loadTasks(projectIds);
    }
    return this.taskApiService.getTasks(undefined, undefined, projectIds);
  }

  loadTasks(projectIds?: number[]): void {
    this.loading = true;
    this.taskApiService
      .getTasks(undefined, undefined, projectIds)
      .pipe(
        first(),
        tap((resp) => {
          this.loading = false;
          this._tasks$.next(resp);
        }),
      )
      .subscribe();
  }

  getTask(id: string): Observable<ProjectTaskDto> {
    return this.taskApiService.getTask(id);
  }

  updateTask(
    id: string,
    body: UpdateProjectTaskRequest,
  ): Observable<ProjectTaskDto> {
    return this.taskApiService.updateTask(id, body);
  }

  createTask(body: CreateProjectTaskRequest): Observable<ProjectTaskDto> {
    return this.taskApiService
      .createTask(body)
      .pipe(tap(() => this.loadTasks([body.projectId])));
  }

  deleteTask(id: number): Observable<unknown> {
    return this.taskApiService.deleteTask(id);
  }

  clearState(): void {
    this._tasks$.next([]);
  }
}
