import { Injectable } from '@angular/core';
import {
  BaseRecurInfoRequest,
  MonthlyByWeekDayRecurInfoRequest,
  RecurrenceInfoType,
  RecurrenceTypeEnum,
  WeeklyRecurInfoRequest,
} from '@models/recurrence';

@Injectable({ providedIn: 'root' })
export class RecurrenceService {
  getRecurrenceData(
    formValue: any,
    type: RecurrenceTypeEnum,
  ): RecurrenceInfoType {
    switch (type) {
      case RecurrenceTypeEnum.SINGLE:
      case RecurrenceTypeEnum.DAILY:
      case RecurrenceTypeEnum.MONTHLY_BY_MONTH_DAY:
        return this.baseRecurrenceData(type, formValue);
      case RecurrenceTypeEnum.WEEKLY:
        return this.weeklyData(formValue);
      case RecurrenceTypeEnum.MONTHLY_BY_WEEK_DAY:
        return this.monthlyByWeekDayData(formValue);
    }
  }

  private baseRecurrenceData(
    type: RecurrenceTypeEnum,
    formValue: any,
  ): BaseRecurInfoRequest {
    const res: BaseRecurInfoRequest = {
      type,
    };
    if (formValue.recurEnd) {
      res['recurEnd'] = formValue.recurEnd;
    }
    if (formValue.each) {
      res['each'] = +formValue.each;
    }
    return res;
  }

  private weeklyData(formValue: any): WeeklyRecurInfoRequest {
    const res = this.baseRecurrenceData(RecurrenceTypeEnum.WEEKLY, formValue);
    if (formValue.daysOfWeek) {
      (res as WeeklyRecurInfoRequest)['daysOfWeek'] = formValue.daysOfWeek;
    }
    return res;
  }

  private monthlyByWeekDayData(
    formValue: any,
  ): MonthlyByWeekDayRecurInfoRequest {
    const res = this.baseRecurrenceData(
      RecurrenceTypeEnum.MONTHLY_BY_WEEK_DAY,
      formValue,
    );

    if (formValue.lastWeek) {
      (res as MonthlyByWeekDayRecurInfoRequest)['lastWeek'] =
        formValue.lastWeek;
    }

    return res;
  }
}
