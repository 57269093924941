import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AddGroupMemberProps,
  CreateGroupProps,
  DeleteGroupResult,
  GroupDto,
  GroupMemberDto,
} from '@models/group-api';
import { GroupApiService } from './api';

@Injectable({ providedIn: 'root' })
export class GroupService {
  constructor(private groupApiService: GroupApiService) {}

  getGroup(id: number): Observable<GroupDto[]> {
    return this.groupApiService.getGroup(id);
  }

  getMyGroup(): Observable<GroupDto[]> {
    return this.groupApiService.getMyGroup();
  }

  getGroups(): Observable<GroupDto[]> {
    return this.groupApiService.getGroups();
  }

  createGroup(body: CreateGroupProps): Observable<unknown> {
    return this.groupApiService.createGroup(body);
  }

  removeGroup(id: number): Observable<DeleteGroupResult> {
    return this.groupApiService.removeGroup(id);
  }

  getGroupById(id: number): Observable<GroupDto> {
    return this.groupApiService.getGroupById(id);
  }

  getGroupMembers(id: number): Observable<GroupMemberDto[]> {
    return this.groupApiService.getGroupMembers(id);
  }

  addGroupMember(id: number, body: AddGroupMemberProps): Observable<unknown> {
    return this.groupApiService.addGroupMember(id, body);
  }

  removeGroupMember(id: number, employeeId: string): Observable<unknown> {
    return this.groupApiService.removeGroupMember(id, employeeId);
  }

  // updateAssignedGroup(currentIds: any[]): Observable<any> {
  //   return this.groupApiService.updateAssignedGroup(currentIds)
  // }
}
