import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectDto } from '@models/project';
import { ImportJiraApiService } from './api/import-jira.api.service';
import { ImportJiraProjectRequest } from '@models/import-jira';

@Injectable({ providedIn: 'root' })
export class ImportJiraService {
  constructor(private importJiraApiService: ImportJiraApiService) {}

  importJiraProject(body: ImportJiraProjectRequest): Observable<ProjectDto> {
    return this.importJiraApiService.importJiraProject(body);
  }
}
